<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip
          v-for="(accion, index) in formattedItem.acciones"
          :key="index"
          small
          label
          dark
          :color="ACCION_EJEC.colores[accion.idest_accion_ejec]"
          class="mt-1 ml-1"
        >
          {{ accion.cant_acciones }} {{ accion.descripcion_corta }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { ACCION_EJEC } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      ACCION_EJEC: ACCION_EJEC,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.descripcion
      item.subtitle = this.$online.materialSistema.resumenFichaTecnica(item).join(' - ')
      item.badge = 'OrdenTrabajoMatsist'
      item.badgeColor = 'OrdenTrabajoMatsist'
      return item
    }
  },
}
</script>
